// client/src/components/QuestionnairePage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BuyerQuestionnaire({ appData }) {
  const navigate = useNavigate();
  const socket = appData || {}
  // Sample questionnaire questions
  const questions = [
    {
      question: 'How satisfied are you with the experiment?',
      type: 'rating', // Custom type to handle different input types
    },
    {
      question: 'What did you like the most about the experiment?',
      type: 'text',
    },
    {
      question: 'Any suggestions for improvement?',
      type: 'text',
    },
    {
      question: 'Would you participate in a similar study again?',
      type: 'radio',
      options: ['Yes', 'No', 'Maybe'],
    },
    // Add more questions as needed
  ];

  const initialAnswers = questions.map(() => '');
  const [answers, setAnswers] = useState(initialAnswers);
  const [errors, setErrors] = useState({});

  const handleInputChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const validateForm = async () => {
      const newErrors = {};
      answers.forEach((answer, index) => {
          if (!answer) {
              newErrors[index] = 'This question is required';
          }
      });

      setErrors(newErrors);

      const isValid = Object.keys(newErrors).length === 0;

      if (isValid) {
          try {
              // Prepare the request body
              const requestBody = {
                  user_id: socket.id, // Replace with the appropriate user ID
                  questionnaire_data: answers, // Replace with the actual questionnaire answers
              };

              // Make the POST request to the backend API
              const response = await fetch('/quizzes', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(requestBody),
              });

              if (response.ok) {
                  const newQuestionnaire = await response.json();
                  console.log('New questionnaire added to the database:', newQuestionnaire);
              } else {
                  console.error('Failed to add the questionnaire:', response.statusText);
              }
          } catch (err) {
              console.error('Error adding questionnaire:', err);
          }
      }

      return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Optionally, process the answers or send them to the server
      // For example:
      // fetch('/api/questionnaire', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ answers }),
      // });
      navigate('/payment'); // Navigate to a thank you or completion page
    }
  };

  return (
    <div className="questionnaire-container">
      <h1>Post-Experiment Buyer Questionnaire</h1>
      <form className="questionnaire-form" onSubmit={handleSubmit}>
        {questions.map((q, index) => (
          <div key={index} className="questionnaire-question">
            <p>
              <strong>
                {index + 1}. {q.question}
              </strong>
            </p>
            <div className="questionnaire-input">
              {q.type === 'text' && (
                <textarea
                  value={answers[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  rows="4"
                ></textarea>
              )}
              {q.type === 'rating' && (
                <div className="rating-options">
                  {[1, 2, 3, 4, 5].map((num) => (
                    <label key={num}>
                      <input
                        type="radio"
                        name={`question-${index}`}
                        value={num}
                        checked={answers[index] === String(num)}
                        onChange={() => handleInputChange(index, String(num))}
                      />
                      {num}
                    </label>
                  ))}
                </div>
              )}
              {q.type === 'radio' && (
                <div className="radio-options">
                  {q.options.map((option, idx) => (
                    <label key={idx}>
                      <input
                        type="radio"
                        name={`question-${index}`}
                        value={option}
                        checked={answers[index] === option}
                        onChange={() => handleInputChange(index, option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}
            </div>
            {errors[index] && <span className="error">{errors[index]}</span>}
          </div>
        ))}
        <button type="submit" className="questionnaire-submit-button">
          Submit
        </button>
      </form>
    </div>
  );
}

export default BuyerQuestionnaire;