import React, {useEffect} from 'react';
import Content from '../components/MainPage/Content';
import Chat from '../components/MainPage/Chat';
import Timer from '../components/MainPage/Timer';
import Warning from '../components/MainPage/Warning';

function MainPage({ appData }) {
  const {role, socket}  = appData || {};
  
  useEffect(() => {
    // Notify server when user reaches /main
    if(socket){
      socket.emit('reachedMain');
    }
  }, [socket]);

  return (
    <div>
      {role ? (
        <div className="App">
          <Timer appData={appData}/>
          <div className="main-container">
            <Content appData={appData}/>
            <Chat appData={appData}/>
          </div>
        </div>
      ): (
        <div>
          <Warning appData={appData}/>
          <div className="WaitingRoom">
            <div className="waiting-container">
              <Content appData={appData}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainPage;