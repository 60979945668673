import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Timer = ({ appData }) => {
  const {  alert, setAlert, isRunning }  = appData || {}; // Access room context
  const [timeLeft, setTimeLeft] = useState(2 * 60); // 5 minutes in seconds
  const [numWarnings, setNumWarnings] = useState(0); // 5 minutes in seconds
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            if(numWarnings === 0){
                setAlert("You have been waiting for 5 minutes without a partner. After 10 minutes if you are still waiting, you will be paid for this expeirment.")
            }else{
                clearInterval(timer);
                if(!alert){
                  navigate('/timeoutPage');
                }
            }
            setNumWarnings(1)
          }
          if(timeLeft <= -1){
            navigate('/timeoutPage');
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [alert, isRunning, navigate, numWarnings, setAlert, timeLeft]);

  // Format the time as MM:SS

  return (
    <div>
    </div>
  );
};

export default Timer;