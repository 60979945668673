/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import Markdown from 'markdown-to-jsx'
import rejectionPageInstructions from '!!raw-loader!../markdown/rejectionPageInstructions.md'

function RejectionPage({ appData }) {
    return (
      <div>
        <div className="markdown-container">
            <Markdown>
                {rejectionPageInstructions}
            </Markdown>
        </div>
        
      </div>
    );
  }
  
  export default RejectionPage;