import React, { useState } from 'react';

const Slider = ({ appData }) => {
  const { roomName, socket, role }  = appData || {};
  const [sliderValue, setSliderValue] = useState(50); // Default slider value
  const offerAppend = process.env.REACT_APP_OFFER_APPEND

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const handleSliderSubmit = () => {
    if (roomName && role) {
      let message = `${sliderValue}${offerAppend}`;
      socket.emit("sendOffer", {roomName, message, role})
    }
  };

  const handleInputChange = (e) => {
    const value = Math.min(100, Math.max(0, Number(e.target.value))); // Clamp between 0 and 100
    setSliderValue(value);
  };

  return (
    <div className="slider-container">
      <div className="slider-row">
        <div className="slider-label"> Your Offer: </div> 
        <input
          type="number"
          min="0"
          max="99"
          value={sliderValue}
          onChange={handleInputChange}
          className="slider-value w-25"
        />
        <p className="slider-value">
          {offerAppend}
        </p>
      </div>
      {/* Horizontal row for slider and button */}
      <div className="slider-row">
        <input
          type="range"
          min="0"
          max="99"
          value={sliderValue}
          onChange={handleSliderChange}
          className="slider w-25"
        />
        <button onClick={handleSliderSubmit} className="slider-submit">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Slider;