import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import io from 'socket.io-client';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import LandingPage from './pages/LandingPage';
import MainPage from './pages/MainPage';
import TermsPage from './pages/TermsPage';
import InfoPage from './pages/InfoPage';
import QuizPage from './pages/QuizPage';
import BuyerQuestionnaire from './pages/BuyerQuestionnaire';
import SellerQuestionnaire from './pages/SellerQuestionnaire';
import PaymentPage from './pages/PaymentPage';
import TimeoutPage from './pages/TimeoutPage';
import RejectionPage from './pages/RejectionPage';
import New from './pages/New'


//const socket = io('http://localhost:5000');
const socket = io(process.env.REACT_APP_SERVER_URL || window.location.origin);

function App() {
  const [roomName, setRoomName] = useState('');
  const [role, setRole] = useState('');
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState('Connecting...');
  const [buyerOffer, setBuyerOffer] = useState('');
  const [sellerOffer, setSellerOffer] = useState('');
  const [alert, setAlert] = useState('');
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  const [buyerConfirmedOffer, setbuyerConfirmedOffer] = useState(false);
  const [sellerConfirmedOffer, setsellerConfirmedOffer] = useState(false);
  const [sellerAudioAccepted, setSellerAudioAccepted] = useState(false);
  const [sellerVideoAccepted, setSellerVideoAccepted] = useState(false);
  const [buyerAudioAccepted, setBuyerAudioAccepted] = useState(false);
  const [buyerVideoAccepted, setBuyerVideoAccepted] = useState(false);
  const [buyerCountdown, setbuyerCountdown] = useState(null);
  const [sellerCountdown, setsellerCountdown] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [partnerId, setPartnerId] = useState(false); 
  const [myId, setMyId] = useState(false); 
  useEffect(() => {
    document.title = "Agent Selections";
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected:', socket.id);
      setStatus('Connected');
    })

    socket.on('waiting', () =>{
      setStatus('Waiting for another user to join...')
    })

    socket.on('joinRoom', (room) => {
      setRoomName(room);
      setIsRunning(true)
      //setStatus(`Connected to ${room}`);
      setMessages(["Type in the chat below..."]);
    });

    socket.on('assignRole', (role) => {
      setRole(role);
    });

    socket.on('assignPartner', (partnerId) => {
      setPartnerId(partnerId);
    });

    socket.on('assignSelf', (myId) => {
      setMyId(myId);
    });

    socket.on('receiveOffer', (msg) => {
      let newMessage = msg 
      newMessage.message = "Made an offer for " + newMessage.message
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      if(msg.role === "Buyer"){

        setBuyerOffer(parseInt(msg.message.replace(/\D/g,'')));
        setbuyerCountdown(30); 
      }else{
        setSellerOffer(parseInt(msg.message.replace(/\D/g,'')));
        setsellerCountdown(30); 
      }
    });

    socket.on('rejectedOffer', (msg) => {
      console.log(msg.rejectOfferMessage)

      msg = {role: msg.role, message: msg.rejectOfferMessage} 
      
      setMessages((prevMessages) => [...prevMessages, msg]);
      setAlert(false)
      if(msg.role === "Buyer"){
        setSellerOffer(null)
        setsellerCountdown(0)
      }else if(msg.role === "Seller"){
        setBuyerOffer(null)
        setbuyerCountdown(0)
      }else{
        console.log("Error in rejected offer")
      }
    });

    socket.on('rescindedOffer', (msg) => {
      let newMessage = msg 
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      if(msg.role === "Buyer"){
        setBuyerOffer(null)
        setbuyerCountdown(0)
      }else if(msg.role === "Seller"){
        setSellerOffer(null)
        setsellerCountdown(0)
      }else{
        console.log("Error in rescind offer")
      }
    });

    // Listen for received messages
    socket.on('receiveMessage', (msg) => {
      let newMessage = msg 
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    socket.on('acceptedOffer', (msg) => {
      let newMessage = msg 
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setAlert("Offer Accepted!! Click to Confirm Offer.")
      setAcceptedOffer(true)
    });

    socket.on('confirmedOffer', (msg) => {
      let newMessage = msg 
      newMessage.role = msg.confirmedRole
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      if(msg.confirmedRole === 'Buyer'){
        console.log("Setting buyer confirmed to true")
        setbuyerConfirmedOffer(true)
      } 
      if(msg.confirmedRole === 'Seller'){
        console.log("Setting seller confirmed to true")
        setsellerConfirmedOffer(true)
      }
    });
    

    return () => {
      socket.off('waiting')
      socket.off('joinRoom')
      socket.off('assignRole')
      socket.off('confirmedOffer')
      socket.off('receiveOffer')
      socket.off('rejectedOffer')
      socket.off('rescindedOffer')
      socket.off('receiveMessage')
      socket.off('acceptedOffer');
      //socket.disconnect();  // Clean up connection
    };
  }, [buyerOffer, buyerConfirmedOffer, sellerOffer, sellerConfirmedOffer]);

  
  // Use useEffect to handle when both confirmations are true
  useEffect(() => {
      if (buyerConfirmedOffer && sellerConfirmedOffer) {
          // Emit an event to notify both users
          let message = 'Both parties confirmed the offer!';
          socket.emit('bothConfirmOffer', { roomName, message });
          message = ""
          // Add a confirmed offer to the database
          const addConfirmedOffer = async () => {
              try {
                  const requestBody = {
                      room_name: roomName,
                      status: 'confirmed', // Update the offer status to confirmed
                      confirmed: true, // Mark the offer as confirmed
                  };

                  // Make the POST request to the backend API
                  const response = await fetch('/offers', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(requestBody),
                  });

                  if (response.ok) {
                      const confirmedOffer = await response.json();
                      console.log('Confirmed offer added to the database:', confirmedOffer);
                  } else {
                      console.error('Failed to add the confirmed offer:', response.statusText);
                  }
              } catch (err) {
                  console.error('Error adding confirmed offer:', err);
              }
          };

          addConfirmedOffer();
      }
    }, [buyerConfirmedOffer, sellerConfirmedOffer, roomName]);


    const handleCancel = async (e) => {
        e.preventDefault();
    
        let rejectOfferMessage = "Rejected the offer";
        // Emit the reject offer message
        socket.emit('rejectOffer', { roomName, rejectOfferMessage, role });
        setAlert(null);
        try {
            // Prepare the request body
            const requestBody = {
                room_name: roomName,
                status: 'cancelled', // Mark the offer as cancelled
            };
    
            // Make the POST request to the backend API
            const response = await fetch('/offers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                const cancelledOffer = await response.json();
                console.log('Cancelled offer added to the database:', cancelledOffer);
            } else {
                console.error('Failed to add the cancelled offer:', response.statusText);
            }
        } catch (err) {
            console.error('Error cancelling offer:', err);
        }
    };
  

  const handleConfirm = useCallback(async () => {
      if (!socket) {
          console.error("Socket is not connected.");
          return;
      }

      let message = '';
      if (role === "Buyer") {
        message = 'Waiting for seller to confirm offer.';
      } else if (role === "Seller") {
        message = 'Waiting for buyer to confirm offer.';
      }

      setAlert(message);

      // Emit the accept offer message
      socket.emit('confirmOffer', { roomName, message, role });
      message = ""

      try {
          // Prepare the request body
          const requestBody = {
              room_name: roomName,
              status: 'pending', // Default status, can be updated as per requirement
              confirmed: true, // Mark the offer as confirmed
          };

          // Make the POST request to the backend API
          const response = await fetch('/offers', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
          });

          if (response.ok) {
              const savedOffer = await response.json();
              console.log('Offer confirmed and saved to the database:', savedOffer);
          } else {
              console.error('Failed to confirm the offer:', response.statusText);
          }
      } catch (err) {
          console.error('Error confirming offer:', err);
      }
  }, [roomName, role, setAlert]);


  const handleAccept = useCallback(async () => {
      if (!socket) {
          console.error("Socket is not connected.");
          return;
      }

      let message = 'Accepted the offer!';
      if(role === "Buyer"){
        message = 'Accepted the offer of ' + sellerOffer;
      }
      if(role === "Seller"){
        message = 'Accepted the offer of ' + buyerOffer; 
      }
      
      socket.emit('acceptOffer', { roomName, message, role });
      message = ""
      try {
          // Prepare the request body
          const requestBody = {
              room_name: roomName,
              status: 'accepted', // Update the offer status
              accepted: true, // Mark the offer as accepted
          };

          // Make the POST request to the backend API
          const response = await fetch('/offers', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
          });

          if (response.ok) {
              const savedOffer = await response.json();
              console.log('Offer accepted and saved to the database:', savedOffer);
          } else {
              console.error('Failed to accept the offer:', response.statusText);
          }
      } catch (err) {
          console.error('Error accepting offer:', err);
      }
  }, [buyerOffer, sellerOffer, roomName, role]);

  const appData = {
    socket: socket,
    partnerId: partnerId,
    myId: myId,
    handleCancel: handleCancel,
    handleAccept: handleAccept,
    handleConfirm: handleConfirm,
    isRunning: isRunning,
    buyerConfirmedOffer: buyerConfirmedOffer,
    sellerConfirmedOffer: sellerConfirmedOffer,
    acceptedOffer: acceptedOffer, 
    setAcceptedOffer: setAcceptedOffer,
    buyerCountdown: buyerCountdown,
    setbuyerCountdown: setbuyerCountdown,
    sellerCountdown: sellerCountdown,
    setsellerCountdown: setsellerCountdown,
    sellerAudioAccepted: sellerAudioAccepted, 
    setSellerAudioAccepted: setSellerAudioAccepted, 
    sellerVideoAccepted: sellerVideoAccepted, 
    setSellerVideoAccepted: setSellerVideoAccepted, 
    buyerAudioAccepted: buyerAudioAccepted, 
    setBuyerAudioAccepted: setBuyerAudioAccepted, 
    buyerVideoAccepted: buyerVideoAccepted, 
    setBuyerVideoAccepted: setBuyerVideoAccepted, 
    alert: alert, 
    setAlert: setAlert, 
    buyerOffer: buyerOffer, 
    setBuyerOffer: setBuyerOffer, 
    sellerOffer: sellerOffer, 
    setSellerOffer: setSellerOffer, 
    status: status, 
    setStatus: setStatus, 
    roomName: roomName, 
    setRoomName: setRoomName, 
    role: role, 
    messages: messages, 
    setMessages: setMessages,
  }
  return (
    <Router>
      <Routes>
      <Route path="/" element={<LandingPage appData={appData}/>} />
      <Route path="/info" element={<InfoPage appData={appData}/>} />
      <Route path="/terms" element={<TermsPage appData={appData}/>} />
      <Route path="/quiz" element={<QuizPage appData={appData}/>} />
      <Route path="/main" element={<MainPage appData={appData}/>} />
      <Route path="/buyerQuestionnaire" element={<BuyerQuestionnaire appData={appData}/>} />
      <Route path="/sellerQuestionnaire" element={<SellerQuestionnaire appData={appData}/>} />
      <Route path="/timeoutPage" element={<TimeoutPage appData={appData}/>} />
      <Route path="/payment" element={<PaymentPage appData={appData}/>} />
      <Route path="/new" element={<New appData={appData}/>} />
      {/* New Catch-All Route */}
      <Route path="/*" element={<RejectionPage appData={appData}/>} />
      </Routes>
    </Router>
  );
}

export default App;