// client/src/components/ChatPanel.js
// Import necessary hooks and libraries
import React, { useState, useEffect} from 'react';
import Offer from './Offer'
import Accept from './Accept'
import Confirm from './Confirm'
import Video from '../Communication/Video'
import Audio from '../Communication/Audio'
import ChatGPT from '../Communication/ChatGPT'

function Chat({ appData }) {
  const { roomName, messages, socket, role, partnerId, myId }  = appData || {};
  const [input, setInput] = useState('');

  useEffect(() => {
    //const chimeSound = new Audio('../../../public/audio/chime.mp3');
    //chimeSound.play();
    console.log("Playing chime")
  }, []); // Empty dependency array ensures this runs only once

  const handleSendMessage = async (e) => {
      e.preventDefault();

      if (!socket) {
          console.error("Socket is not connected.");
          return;
      }

      const message = input;

      if (message.trim() === "") {
          console.error("Cannot send an empty message.");
          return;
      }

      socket.emit('sendMessage', { roomName, message, role });
      // 'http://localhost:5000/messages'
      try {
        const response = await fetch('/messages', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  roomName: roomName,     // Use the room Name
                  senderSocketId: myId,       // Use the sender's ID
                  recipientSocketId: partnerId, // Use the recipient's ID
                  message_text: message.trim(),  // Set the message text
              }),
          });

          if (response.ok) {
              const savedMessage = await response.json();
              console.log('Message saved:', savedMessage);
              return savedMessage;
          } else {
              console.error('Failed to save message:', response.statusText);
          }
      } catch (error) {
          console.error('Error sending message:', error);
      }

      // Clear the input field
      setInput('');
  };

  return (
    <div>
    {role ? (
      <div min-height="100%">
        <div className="chat-panel" min-height="95%">
          <Accept appData={appData}/>
          <Confirm appData={appData}/>
          <Offer appData={appData}/>

          {/* Status used for testing and debugging */}
          {/* <div className="chat-status">{status}</div> */}

          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div>
                {(msg && msg.role) && (
                  <div key={index}>{msg.role}: {msg.message}</div>
                )}
              </div>
            ))}
          </div>

          
          {process.env.REACT_APP_ENABLE_VIDEO && <div> <Video appData={appData}/> </div>}
          {process.env.REACT_APP_ENABLE_AUDIO && <div> <Audio appData={appData}/> </div>}
          {process.env.REACT_APP_ENABLE_GPT && <div> <ChatGPT appData={appData}/> </div>}
          

          <form className="chat-input" onSubmit={handleSendMessage}>
            <input
              type="text"
              placeholder="Type a message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              disabled={!roomName}
              maxlength="500"
            />
            <button type="submit" disabled={!roomName}>
              Send
            </button>
          </form>
        </div>
      </div>
      ):
      (
        <div>
        </div>
      )}
    </div>
  );
}

export default Chat;