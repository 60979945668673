// client/src/components/QuizPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function QuizPage({ appData }) {
  const navigate = useNavigate();
  const { myId }  = appData || {};
  const {prolificPID, studyID, sessionID, workerId, socketId} = useLocation() || {}
  // Sample quiz questions
  const questions = [
    {
      question: 'What is the capital of France?',
      options: ['London', 'Berlin', 'Paris', 'Rome'],
    },
    {
      question: 'Which planet is known as the Red Planet?',
      options: ['Earth', 'Mars', 'Jupiter', 'Venus'],
    },
  ];

  const [answers, setAnswers] = useState(Array(2).fill(''));
  const [errors, setErrors] = useState({});

  const handleOptionChange = (questionIndex, option) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = option;
    setAnswers(newAnswers);
  };

  const validateForm = () => {
    const newErrors = {};
    answers.forEach((answer, index) => {
      if (!answer) {
        newErrors[index] = 'Please select an option';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if(validateForm()){
        try {
          const response = await fetch('/quizzes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    questions: questions,
                    answers: answers,
                    user_id: myId,
                }),
            });
  
            if (response.ok) {
                const savedQuiz = await response.json();
                console.log('Message saved:', savedQuiz);
                navigate('/main', { state: { prolificPID, studyID, sessionID, workerId, socketId } }); 
                return savedQuiz;
            } else {
                console.error('Failed to save message:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
      }
  };


  return (
    <div className="quiz-container">
      <h1>Quiz</h1>
      <form className="quiz-form" onSubmit={handleSubmit}>
        {questions.map((q, index) => (
          <div key={index} className="quiz-question">
            <p>
              <strong>
                {index + 1}. {q.question}
              </strong>
            </p>
            <div className="quiz-options">
              {q.options.map((option, idx) => (
                <label key={idx} className="quiz-option">
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleOptionChange(index, option)}
                  />
                  {option}
                </label>
              ))}
            </div>
            {errors[index] && <span className="error">{errors[index]}</span>}
          </div>
        ))}
        <button type="submit" className="quiz-submit-button">
          Enter
        </button>
      </form>
    </div>
  );
}

export default QuizPage;