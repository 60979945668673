import React, { useEffect } from 'react';
import Slider from './Slider';

const Offer = ({ appData }) => {
  const { roomName, setAlert, socket, role, buyerOffer, setBuyerOffer, sellerOffer, setSellerOffer, buyerCountdown, setbuyerCountdown, sellerCountdown, setsellerCountdown, }  = appData || {};
  const offerTimer = process.env.REACT_APP_SHOW_OFFER_COUNTDOWN
  const offerCountdown = process.env.REACT_APP_SHOW_OFFER_COUNTDOWN_NUMBER

  useEffect(() => {
    let timer;
    if (buyerCountdown > 0) {
      timer = setInterval(() => {
        setbuyerCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (buyerCountdown === 0) {
        setBuyerOffer(null)
    }
    return () => clearInterval(timer);
  }, [setbuyerCountdown, buyerCountdown, setBuyerOffer]);

  useEffect(() => {
    let timer;
    if (sellerCountdown > 0) {
      timer = setInterval(() => {
        setsellerCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (sellerCountdown === 0) {
        setSellerOffer(null)
    }
    return () => clearInterval(timer);
  }, [sellerCountdown, setsellerCountdown, setSellerOffer]);

  const handleReject = (e) => {
    e.preventDefault();
    
    let rejectOfferMessage = ""
    if(role === "Buyer"){
      console.log("Seller offer in handle reject", sellerOffer)
      rejectOfferMessage = "Rejected an offer for $" + sellerOffer
        setSellerOffer(null)
        setsellerCountdown(0)
    }else{
      rejectOfferMessage = "Rejected an offer for $" + buyerOffer
        setBuyerOffer(null)
        setbuyerCountdown(0)
    }
    
    socket.emit("rejectOffer", {roomName, rejectOfferMessage, role})
  }

  const handleRescind = (e) => {
    let message = ""
    e.preventDefault();
    if(role === "Buyer"){
      message = "Rescinded an offer for $" + buyerOffer 
      setBuyerOffer(null)
      setbuyerCountdown(0)
    }else{
      message = "Rescinded an offer for $" + sellerOffer
      setSellerOffer(null)
      setsellerCountdown(0)
    }
    
    socket.emit("rescindOffer", {roomName, message, role})
  }

  const handleAccept = (e) => {
    e.preventDefault();
    setAlert('Accept the proposed offer?');
  }

  return (
    <div>
        <div className="offer-container">
            {/* Buyer Offer Section */}
            <div className="offer-section">
                <h3 align="center">
                  {buyerOffer && (
                    <p className="offer-notification">
                      <h5>
                      Buyer Offer: {buyerOffer}
                      </h5>
                    </p>
                  )}
                </h3>
                
                {(role === 'Seller' && !buyerOffer) && (
                    <h3>
                      No Buyer Offer
                    </h3>
                )}
                
                {(role === 'Buyer' && !buyerCountdown) && (
                    <Slider appData={appData}/>
                )}
                
                {buyerOffer && (
                    <div className="countdown-timer">
                        {offerCountdown && (
                          <p>{buyerCountdown} seconds left</p>
                        )}
                        
                        {offerTimer==="true" ?? (
                          <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped bg-success"
                                role="progressbar"
                                style={{ width: `${((30 - buyerCountdown) / 30) * 100}%` }}
                                aria-valuenow={30 - buyerCountdown}
                                aria-valuemin="0"
                                aria-valuemax="30"
                            ></div>
                          </div>
                        )}
                    </div>
                    )
                }
                {(buyerOffer) && (
                  <div>
                    {role === 'Seller' ? (
                      <div>
                          <div className="d-flex flex-row"  width="100%">
                          <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleAccept}>
                              Accept
                          </button>

                          <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleReject}>
                              Reject
                          </button>
                      </div>
                      </div>
                    ): (
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleRescind}>
                              Rescind
                          </button>
                      </div>
                    )}
                  </div>
                )} 
            </div>

            {/* Seller Offer Section */}
            <div className="offer-section">
                {sellerOffer && (
                  <h3 align="center">
                  {sellerOffer && (
                    <p className="offer-notification">
                      <h5>
                      Seller Offer: {sellerOffer}
                      </h5>
                    </p>
                  )}
                </h3>
                )}

                {(role === 'Buyer' && !sellerOffer) && (
                    <h3>
                      No Seller Offer
                    </h3>
                )}
                
                {(role === 'Seller' && !sellerCountdown) && (
                    <Slider appData={appData}/>
                )}
                {sellerOffer && (
                    <div className="countdown-timer"> 
                      {offerCountdown && (
                          <p>{sellerCountdown} seconds left</p>
                        )}
                        {offerTimer==="true" ?? (
                          <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped bg-success"
                                role="progressbar"
                                style={{ width: `${((30 - sellerCountdown) / 30) * 100}%` }}
                                aria-valuenow={30 - sellerCountdown}
                                aria-valuemin="0"
                                aria-valuemax="30"
                            ></div>
                          </div>
                        ) }
                        
                    </div>
                    )
                }

                {(sellerOffer) && (
                  <div>
                    {role === 'Buyer' ? (
                      <div>
                          <div className="d-flex flex-row"  width="100%">
                          <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleAccept}>
                              Accept
                          </button>

                          <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleReject}>
                              Reject
                          </button>
                      </div>
                      </div>
                    ): (
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleRescind}>
                              Rescind
                          </button>
                      </div>
                    )}
                  </div>
                )} 
            </div>
        </div>
    </div>
  );
};

export default Offer;