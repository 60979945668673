/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import Markdown from 'markdown-to-jsx'
import timeoutPageInstructions from '!!raw-loader!../markdown/timeoutPageInstructions.md'
import { useNavigate } from 'react-router-dom';

function TimeoutPage({ appData }) {
    const {  role }  = appData || {};
    const navigate = useNavigate();

    const handleRedirect = () => {
       if(role === 'Buyer'){
        navigate('/buyerQuestionnaire');
       }else if(role === 'Seller '){
        navigate('/sellerQuestionnaire');
       }else {
        navigate('/reject');
       }
        
    };

    return (
      <div className="markdown-container text-center">
        <Markdown>
            {timeoutPageInstructions}
        </Markdown>
        <div className="row mt-3">
          <div className="col-12">
            <button className="btn btn-primary w-100" onClick={handleRedirect}>Okay</button>
          </div>
        </div>
      </div>
    );
  }
  
  export default TimeoutPage;