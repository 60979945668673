// client/src/components/LandingPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function LandingPage({ appData }) {
  const { socket }  = appData || {};
  const [workerId, setWorkerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  

  const location = useLocation();
  
  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  let prolificPID = searchParams.get("PROLIFIC_PID");
  let studyID = searchParams.get("STUDY_ID");
  let sessionID = searchParams.get("SESSION_ID");
  
  if(prolificPID === null){
    prolificPID = "TESTING"
  }
  if(studyID === null){
    studyID = "TESTING"
  }
  if(sessionID === null){
    sessionID = "TESTING"
  }

  console.log(prolificPID, studyID, sessionID)
  

  const validateForm = () => {
    const newErrors = {};
    if (!workerId.trim()) {
      newErrors.workerId = 'Worker ID is required';
    }
    if (!agreed) {
      newErrors.agreed = 'You must agree to the terms and services';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 'http://localhost:5000//users'
  const handleSubmit = async (e) => {
      e.preventDefault();

      if (validateForm()) {
        setLoading(true)
        try {
          let socketId = socket.id
          const response = await fetch('/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prolificPID: prolificPID,
                    studyID: studyID,
                    sessionID: sessionID,
                    workerId: workerId,
                    socketId: socketId, 
                }),
            });
  
            if (response.ok) {
                const savedUser = await response.json();
                console.log('User saved:', savedUser);
                navigate('/info', { state: { prolificPID, studyID, sessionID, workerId, socketId } }); 
                return savedUser;
            } else {
                console.error('Failed to save user:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending user:', error);
        }
      }
  };


  return (
    <div className="landing-container">
      {loading && (
          <div className="loading-overlay">
            Loading...
          </div>
        )}
      <h1>Welcome to the Study</h1>
      <form className="landing-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="workerId">Worker ID:</label>
          <input
            type="text"
            id="workerId"
            value={workerId}
            onChange={(e) => setWorkerId(e.target.value)}
          />
          {errors.workerId && <span className="error">{errors.workerId}</span>}
        </div>
        <div className="form-group terms">
          <input
            type="checkbox"
            id="agreed"
            checked={agreed}
            disabled={loading}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          <label htmlFor="agreed">
            I agree to the{' '}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms and Services
            </a>
          </label>
          {errors.agreed && <span className="error">{errors.agreed}</span>}
        </div>
        <button disabled={loading} type="submit">Proceed to Study</button>
      </form>
    <a href="/terms" target="_blank" rel="noopener noreferrer">
        Terms and Services
    </a>
    </div>
  );
}

export default LandingPage;