/* eslint-disable import/no-webpack-loader-syntax */
import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx'
import preTrialInstructions from '!!raw-loader!../../markdown/preTrialInstructions.md'
import buyerInstructions from '!!raw-loader!../../markdown/buyerInstructions.md'
import sellerInstructions from '!!raw-loader!../../markdown/sellerInstructions.md'
import buyerPickerInstructions from '!!raw-loader!../../markdown/buyerPickerInstructions.md'
import sellerPickerInstructions from '!!raw-loader!../../markdown/preTrialInstructions.md'

function Content({ appData }) {
  const { role }  = appData || {};
  const [selectedDocument, setSelectedDocument] = useState('document0');
  let backgroundColor = "white"
  let textAlign = 'left'
  if(role === "Buyer" || role === "Seller"){
    backgroundColor = "white"
    textAlign = "left"
  }else{
    backgroundColor = "lightgray"
    textAlign = "center"
  }
  // Sample documents
  const documents = {
    document0: {
      title: 'Instructions',
      content: "Instructions"
    },
    document1: {
      title: 'HOA Bylaws',
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/HOA.pdf',
    },
    document2: {
      title: 'Home Deed', 
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/Deed.pdf',
    },
    document3: {
      title: 'City Sales Trends',
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/SalesTrends.pdf',
    },
    // Add more documents as needed
  };

  // Get the content of the selected document
  const currentDocument = documents[selectedDocument];
  
  return (
    <div className="content-panel"  style={{ backgroundColor, textAlign}}>
      <div className="document-buttons">
        <div className="flex-container">
          <div className="flex-column">
            <h2>
              Select a document:
            </h2>
          </div>

          <div className="flex-column">
            <div>
              {Object.keys(documents).map((docKey) => (
                <button
                  key={docKey}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setSelectedDocument(docKey)}
                >
                  {documents[docKey].title}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {currentDocument.url && (
        <div overflow="auto" height="100%" className="pdf-container">
          <object width="100%" min-height="400px" height="600vh" data={currentDocument.url} type="application/pdf">   </object>
        </div>
      )}

      {currentDocument.content && (
        <div>
        {(() => {
          if (role === 'Buyer') {
            return (
              <div>
                <Markdown>
                  {buyerInstructions}
                </Markdown> 
              </div>
            )
          } else if (role === 'Seller') {
            return (
              <Markdown>
                {sellerInstructions}
              </Markdown> 
            )
          } else if (role === 'Buyer Picker') {
            return (
              <Markdown>
                {buyerPickerInstructions}
              </Markdown> 
            )
          }else if (role === 'Seller Picker') {
            return (
              <Markdown>
                {sellerPickerInstructions}
              </Markdown> 
            )
          }else {
            return (
              <div>
                <Markdown>
                  {preTrialInstructions}
                </Markdown> 
              </div>
            )
          }
        })()}
      </div>
      )}
    </div>
  );
}

export default Content;