import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Confirm = ({ appData }) => {
  const { alert, setAlert, handleConfirm, socket, role, handleCancel, acceptedOffer }  = appData || {};
  const navigate = useNavigate();

  useEffect(() => {
    if(socket){
      socket.on('bothConfirmedOffer', () => {
        setAlert("Both buyer and seller have confirmed the offer. Redirecting to questionnaire.");
          setTimeout(() => {
          // Navigate based on role
          if (role === 'Buyer') {
            navigate('/buyerQuestionnaire');
          } else {
            navigate('/sellerQuestionnaire');
          }
          }, 3000);
        });
      
      return () => {
        socket.off('bothConfirmedOffer');
      };
    }
  },[socket, navigate, role, setAlert]);

  return (
    <div>
      {(alert && acceptedOffer) && (
        <div className="alert-overlay">
          <div className="alert-box">
            <h2>Alert</h2>
            <h2>{alert}</h2>
            {(alert !== 'Waiting for seller to confirm offer.' && alert !== 'Waiting for buyer to confirm offer.' && alert !== "Both buyer and seller have confirmed the offer. Redirecting to questionnaire.") && (
              <div className="alert-buttons">
                <button className="alert-confirm" onClick={handleConfirm}>
                  Confirm
                </button>
                <button className="alert-cancel" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Confirm;