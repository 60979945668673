// client/src/components/InfoPage.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function InfoPage({ appData }) {
  const [acknowledged, setAcknowledged] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { workerId } = location.state || {}; 

  const handleProceed = () => {
    if (acknowledged) {
      navigate('/quiz', { state: { workerId } });
    } else {
      setError('You must acknowledge that you have read the information.');
    }
  };

  return (
    <div className="info-container">
      <h1>Study Information</h1>
      <div className="info-content">
        <p>
          {/* Replace this with the actual description of your study */}
          Welcome to our research study. The purpose of this study is to examine how individuals interact
          with online platforms. Your participation is voluntary, and you may withdraw at any time. All
          data collected will be kept confidential and used solely for research purposes.
        </p>
        {/* Add more detailed information as needed */}
      </div>
      <div className="info-acknowledgment">
        <label>
          <input
            type="checkbox"
            checked={acknowledged}
            onChange={(e) => {
              setAcknowledged(e.target.checked);
              if (e.target.checked) setError('');
            }}
          />
          I have read and understand the study information provided above.
        </label>
        {error && <span className="error">{error}</span>}
      </div>
      <button className="proceed-button" onClick={handleProceed}>
        Continue to Quiz
      </button>
    </div>
  );
}

export default InfoPage;