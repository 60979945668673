import React from 'react';

const Alert = ({ appData }) => {
  const { alert, handleCancel, handleAccept, acceptedOffer }  = appData || {};

  return (
    <div>
      {(alert && !acceptedOffer) && (
        <div className="alert-overlay">
          <div className="alert-box">
            <h2>Alert</h2>
            <h2>{alert}</h2>
            {alert !== 'Offer Accepted!! Redirecting to questionnaire.' && (
              <div className="alert-buttons">
                <button className="alert-confirm" onClick={handleAccept}>
                  Accept Offer
                </button>
                <button className="alert-cancel" onClick={handleCancel}>
                  Cancel Accept
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;